.aboutproduction {
  display: flex;
  margin: -30px 0 -50px;
}

.aboutproduction__details {
  width: 50%;
}

.aboutproduction__details-title {
  margin-top: 150px;
  margin-bottom: 50px;
}

.aboutproduction__details-btn {
  margin-top: 40px;
  padding: 14px 60px;
  background-color: #2d8d80;
  font-size: 16.07px;
  line-height: 17.52px;
  border-radius: 15px;
  color: var(--white-color);
  cursor: pointer;
  display: block;
  width: max-content;
}

.aboutproduction__img {
  width: 60%;
  object-fit: contain;
}

@media (max-width: 950px) {
  .aboutproduction {
    flex-direction: column;
  }

  .aboutproduction__details {
    width: 100%;
  }

  .aboutproduction__details-title {
    margin-top: 100px;
    margin-bottom: 20px;
  }

  .aboutproduction__img {
    width: 150%;
    margin-left: -25%;
    margin-top: -40px;
  }
}

@media (max-width: 400px) {
  .aboutproduction__details-btn {
    padding: 11px 42px;
    font-size: 14.07px;
    line-height: 16.52px;
    border-radius: 10px;
  }
}
