.blogs {
  background-color: var(--green-semibold);
  padding: 80px 0;
  position: relative;
  overflow: hidden;
}

.blogs .container {
  position: relative;
  z-index: 2;
}

.blogs__wrapper {
  margin-top: 50px;
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
}

.blogs__blog {
  width: 30%;
  max-width: 300px;
  background-color: var(--white-color);
  border-radius: 9px;
  padding: 19px;
}

.blogs__blog-img {
  width: 100%;
  object-fit: contain;
  height: 200px;
}

.blogs__blog-content {
  padding-top: 11px;
}

.blogs__blog-title {
  font-size: 18px;
  line-height: 20.39px;
  font-weight: 400;
  color: #016258;
  margin-bottom: 30px;
}

.blogs__blog-details {
  display: flex;
  justify-content: space-between;
}

.blogs__blog-date {
  color: #747474;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.blogs__blog-date svg {
  font-size: 16px;
}

.blogs__blog-btn {
  padding: 12px 12px;
  background-color: #016258;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-white);
  display: flex;
  align-items: center;
  font-style: italic;
  gap: 10px;
}

.blogs__btn-icon {
  font-size: 17px;
}

.blogs__pagination {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 50px;
  align-items: flex-end;
}

.blogs__pagination-item {
  background-color: var(--pink-normal);
  padding: 6px 7px;
  font-size: 27.65px;
  font-weight: 700;
  color: #176B62;
  border-radius: 5px;
  opacity: .5;
  /* transition: .5s; */
  cursor: pointer;
}

.blogs__pagination-item.active {
  transform: scale(1.1);
  opacity: 1;
}

.blogs__pagination-btn {
  font-size: 35px;
  background-color: var(--pink-normal);
  color: var(--green-semibold);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  opacity: .5;
  transition: .3s;
  cursor: pointer;
}

.blogs__pagination-btn:hover {
  opacity: 1;
}

.blogs__pagination-next {
  margin-left: 20px;
}

.blogs__pagination-prev {
  margin-right: 20px;
  transform: rotate(180deg);
}

@media (max-width: 950px) {
  .blogs__blog {
    width: 40%;
  }
}

@media (max-width: 700px) {
  .blogs__blog {
    width: 45%;
  }

  .blogs__blog-btn {
    font-size: 12px;
    padding: 9px;
  }
  
  .blogs__blog-title {
    font-size: 16px;
    margin-bottom: 17px;
  }

  .blogs__pagination-item {
    font-size: 18.65px;
  }

  .blogs__pagination-btn {
    font-size: 18px;
  }
}

@media (max-width: 600px) {
  .blogs__blog {
    width: 46%;
  }

  .blogs__wrapper {
    gap: 20px;
  }
}

@media (max-width: 500px) {
  .blogs__blog-btn {
    font-size: 9px;
    padding: 8px;
  }

  .blogs__btn-icon {
    font-size: 12px;
  }

  .blogs__blog-date {
    font-size: 9px;
  }
  
  .blogs__blog-title {
    font-size: 14px;
    margin-bottom: 17px;
    line-height: 17.39px;
  }

  .blogs__pagination-item {
    font-size: 10.65px;
  }

  .blogs__pagination-btn {
    font-size: 14px;
    padding: 2px;
    margin: 0;
  }
}

@media (max-width: 470px) {
  .blogs__blog {
    padding: 9px;
  }

  .blogs__blog-title {
    font-size: 10px;
    margin-bottom: 10px;
    line-height: 13.39px;
  }

  .blogs__blog-date {
    font-size: 6.01px;
  }

  .blogs__blog-btn {
    font-size: 6.43px;
  }
  
  .blogs__btn-icon {
    font-size: 10px;
  }

  .blogs__blog-img {
    height: 145px;
  }
}

@media (max-width: 400px) {
  .blogs__pagination-item {
    font-size: 15.65px;
    padding: 5px 6px;
    border-radius: 3px;
  }

  .blogs__pagination-btn {
    font-size: 20px;
    padding: 2px;
    margin: 0;
  }
}
