@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap');

:root {
  --green-extrabold: #006259;
  --green-bold: #006157;
  --green-semibold: #1A6D64;
  --green-normal: #1E6F65;
  --green-light: #2D8D80;
  --blue-normal: #288DC6;
  --blue-bold: #082856;
  --white-color: #fff;
  --white-light: rgba(255, 255, 255, .13);
  --pink-thin: #FFE5CE;
  --pink-light: #FFE1C7;
  --pink-normal: #F9E0C8;
  --orange-light: #FFC898;
  --orange-thin: #FFE2CA;
  --red-normal: #931a1a;
  --black-color: #000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*::selection {
  background-color: #fff;
  color: var(--green-semibold);
}

/* SCROLLBAR */
*::-webkit-scrollbar-thumb {
  background-color: var(--green-semibold);
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden !important;
  color: var(--white-color);
}

li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background: transparent;
  border: 0;
}

select {
  -webkit-appearance: none;
  border: 0;
  outline: 0;
  background-color: transparent;
  color: inherit;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.hide-overflow {
  overflow: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.font-rubik {
  font-family: 'Rubik', sans-serif;
}

.px-normal {
  padding: 0 150px !important;
}

.spin {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid var(--green-semibold);
  width: 18px;
  height: 18px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.app__title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app__title {
  font-size: 53px;
  line-height: 63.84px;
  color: var(--pink-thin);
}

.app__span {
  padding: 5px 24px;
  background-color: var(--pink-thin);
  border-radius: 10px;
  color: var(--green-semibold);
  display: flex;
  align-items: center;
}

.app__routes {
  display: flex;
  gap: 10px;
  margin: 20px 0 80px;
  border: 0;
  outline: 0;
  font-weight: 300;
}

.container {
  width: 1400px;
  margin: auto;
  padding: 0px 20px;
  position: relative;
  z-index: 2;
}

@media (max-width: 1420px) {
  .container {
    width: 1100px;
    margin: auto;
  }

  .px-normal {
    padding: 0 50px !important;
  }
}

@media (max-width: 1150px) {
  .container {
    width: 900px;
  }

  .px-normal {
    padding: 0 !important;
  }
}

@media (max-width: 950px) {
  .app__span {
    font-size: 14px;
    padding: 5px 19px;
  }

  .container {
    width: 750px;
  }

  .app__title {
    line-height: 56.84px;
    font-size: 45px;
  }
  
  .app__routes {
    margin: 14px 0 70px;
    font-size: 15px;
  }
}

@media (max-width: 800px) {
  .container {
    width: 650px;
  }
}

@media (max-width: 700px) {
  .container {
    width: 550px;
  }
}

@media (max-width: 600px) {
  .app__span {
    font-size: 10px;
    padding: 1px 14px;
    border-radius: 7px;
  }

  .container {
    width: 450px;
  }

  .app__title {
    font-size: 38px;
    line-height: 44.84px;
  }

  .app__routes {
    margin: 12px 0 60px;
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .container {
    width: 90%;
  }
}

@media (max-width: 400px) {
  .app__title {
    font-size: 30px;
    line-height: 28.84px;
  }

  .app__routes {
    margin: 10px 0 40px;
    font-size: 13px;
  }
}

