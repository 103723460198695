.send-btn {
  width: max-content;
}

.button {
  --primary: #f6f8ff;
  --primary-dark: #d1d6ee;
  --primary-darkest: #8a91b4;
  --shadow: #{rgba(#000, 0.3)};
  --text: #362a89;
  --text-opacity: 1;
  --success: #eeecff;
  --success-x: -12;
  --success-stroke: 14;
  --success-opacity: 0;
  --border-radius: 7;
  --overflow: hidden;
  --x: 0;
  --y: 0;
  --rotate: 0;
  --plane-x: 0;
  --plane-y: 0;
  --plane-opacity: 1;
  --trails: #{rgba(#fff, 0.15)};
  --trails-stroke: 57;
  --left-wing-background: var(--primary);
  --left-wing-first-x: 0;
  --left-wing-first-y: 0;
  --left-wing-second-x: 50;
  --left-wing-second-y: 0;
  --left-wing-third-x: 0;
  --left-wing-third-y: 100;
  --left-body-background: var(--primary);
  --left-body-first-x: 51;
  --left-body-first-y: 0;
  --left-body-second-x: 51;
  --left-body-second-y: 100;
  --left-body-third-x: 0;
  --left-body-third-y: 100;
  --right-wing-background: var(--primary);
  --right-wing-first-x: 49;
  --right-wing-first-y: 0;
  --right-wing-second-x: 100;
  --right-wing-second-y: 0;
  --right-wing-third-x: 100;
  --right-wing-third-y: 100;
  --right-body-background: var(--primary);
  --right-body-first-x: 49;
  --right-body-first-y: 0;
  --right-body-second-x: 49;
  --right-body-second-y: 100;
  --right-body-third-x: 100;
  --right-body-third-y: 100;
  display: block;
  cursor: pointer;
  position: relative;
  border: 0;
  padding: 8px 0;
  min-width: 100px;
  text-align: center;
  margin: 0;
  line-height: 24px;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  background: none;
  outline: none;
  color: var(--green-light);
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  .plane,
  .trails {
    pointer-events: none;
    position: absolute;
  }
  .plane {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    filter: drop-shadow(0 3px 6px var(--green-light));
    transform: translate(calc(var(--x) * 1px), calc(var(--y) * 1px))
      rotate(calc(var(--rotate) * 1deg)) translateZ(0);
    .left,
    .right {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: var(--plane-opacity);
      transform: translate(
          calc(var(--plane-x) * 1px),
          calc(var(--plane-y) * 1px)
        )
        translateZ(0);
      &:before,
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border-radius: calc(var(--border-radius) * 1px);
        transform: translate(var(--part-x, 0.4%), var(--part-y, 0))
          translateZ(0);
        z-index: var(--z-index, 2);
        background: var(--background, var(--left-wing-background));
        clip-path: polygon(
          calc(var(--first-x, var(--left-wing-first-x)) * 1%)
            calc(var(--first-y, var(--left-wing-first-y)) * 1%),
          calc(var(--second-x, var(--left-wing-second-x)) * 1%)
            calc(var(--second-y, var(--left-wing-second-y)) * 1%),
          calc(var(--third-x, var(--left-wing-third-x)) * 1%)
            calc(var(--third-y, var(--left-wing-third-y)) * 1%)
        );
      }
    }
    .left:after {
      --part-x: -1%;
      --z-index: 1;
      --background: var(--left-body-background);
      --first-x: var(--left-body-first-x);
      --first-y: var(--left-body-first-y);
      --second-x: var(--left-body-second-x);
      --second-y: var(--left-body-second-y);
      --third-x: var(--left-body-third-x);
      --third-y: var(--left-body-third-y);
    }
    .right:before {
      --part-x: -1%;
      --z-index: 2;
      --background: var(--right-wing-background);
      --first-x: var(--right-wing-first-x);
      --first-y: var(--right-wing-first-y);
      --second-x: var(--right-wing-second-x);
      --second-y: var(--right-wing-second-y);
      --third-x: var(--right-wing-third-x);
      --third-y: var(--right-wing-third-y);
    }
    .right:after {
      --part-x: 0;
      --z-index: 1;
      --background: var(--right-body-background);
      --first-x: var(--right-body-first-x);
      --first-y: var(--right-body-first-y);
      --second-x: var(--right-body-second-x);
      --second-y: var(--right-body-second-y);
      --third-x: var(--right-body-third-x);
      --third-y: var(--right-body-third-y);
    }
  }
  .trails {
    display: block;
    width: 33px;
    height: 64px;
    top: -4px;
    left: 16px;
    fill: none;
    stroke: var(--trails);
    stroke-linecap: round;
    stroke-width: 2;
    stroke-dasharray: 57px;
    stroke-dashoffset: calc(var(--trails-stroke) * 1px);
    transform: rotate(68deg) translateZ(0);
  }
  span {
    display: block;
    position: relative;
    z-index: 4;
    opacity: var(--text-opacity);
    &.success {
      z-index: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 8px;
      transform: translateX(calc(var(--success-x) * 1px)) translateZ(0);
      opacity: var(--success-opacity);
      color: var(--success);
      svg {
        display: inline-block;
        vertical-align: top;
        width: 16px;
        height: 16px;
        margin: 4px 8px 0 0;
        fill: none;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 14px;
        stroke: var(--success);
        stroke-dashoffset: calc(var(--success-stroke) * 1px);
      }
    }
  }
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

// dribbble & twitter
body {
  .dribbble {
    position: fixed;
    display: block;
    right: 20px;
    bottom: 20px;
    img {
      display: block;
      width: 76px;
    }
  }
  .twitter {
    position: fixed;
    display: block;
    right: 112px;
    bottom: 14px;
    svg {
      width: 24px;
      height: 24px;
      fill: white;
    }
  }
}
