.products {
  background-color: var(--green-semibold);
  padding: 80px 0;
  position: relative;
  overflow: hidden;
}

.products .container {
  position: relative;
}

.products__categories-wrapper {
  overflow-x: hidden;
}

.products__categories {
  display: flex;
  gap: 60px;
  border-bottom: 2px solid #8ca999;
  padding: 0 20px 15px;
  overflow-x: auto;
  position: relative;
}

/* SCROLLBAR */
.products__categories::-webkit-scrollbar {
  width: 0px;
  height: 0;
}

.products__category {
  opacity: 0.5;
  transition: 0.3s;
  cursor: pointer;
  font-size: 14.32px;
  line-height: 16.82px;
  font-weight: 400;
  width: max-content;
  position: relative;
}

.products__category.active {
  opacity: 1;
}

.products__category:hover {
  opacity: 1;
}

.products__category-text {
  width: max-content;
}

.products__categories-underline {
  position: absolute;
  width: 130%;
  left: -15%;
  height: 2.5px;
  background-color: var(--pink-thin);
  bottom: -15px;
  z-index: 2;
  transition: .7s;
  opacity: 0;
}

.products__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
  justify-content: center;
  margin-top: 100px;
}

.products__item {
  width: 275px;
  border-radius: 20px;
  background-color: var(--orange-thin);
  padding: 30px 24px 40px;
  color: #006157;
  text-align: center;
  transition: 0.5s !important;
  position: relative;
}

.products__item:hover {
  box-shadow: 0px 0px 10px 0px #fff;
}

.products__item-img {
  margin-bottom: 20px;
  width: 100%;
  object-fit: contain;
  height: 177px;
}

.products__item-title {
  font-size: 16px;
  line-height: 18.8px;
  font-weight: 300;
}

.products__item-btns {
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  opacity: 0;
  transition: 0.3s;
  z-index: 1;
}

.products__item:hover .products__item-btns {
  opacity: 1;
}

.products__item-btn {
  font-size: 30px;
  color: var(--green-semibold);
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px 0px #fff;
  cursor: pointer;
}

.products__modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
}

.products__modal-bg.active {
  opacity: 1;
  pointer-events: all;
}

.products__modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  z-index: 8;
  background-color: var(--orange-thin);
  padding: 50px 80px;
  border-radius: 32px;
  color: var(--green-semibold);
  display: flex;
  gap: 30px;
  width: max-content;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s;
}

.products__modal.active {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  pointer-events: all;
}

.products__modal-title {
  font-size: 32.81px;
  line-height: 46.2px;
}

.products__modal-content {
  display: flex;
  flex-direction: column;
  gap: 60px;
  max-width: 26vw;

}

.products__modal-img {
  object-fit: contain;
  width: 400px;
  max-height: 300px;
}

.products__modal-detail {
  font-size: 18px;
}

.products__modal-btn {
  background-color: #126259;
  border-radius: 28px;
  font-size: 15.45px;
  width: fit-content;
  color: #fff;
  padding: 5px 25px 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}

.products__modal-btn:hover .products__modal-btn-icon {
  transform: translateX(5px);
}

.products__modal-btn-icon {
  background-color: var(--white-color);
  color: var(--green-semibold);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
  font-size: 20px;
  transition: .5s;
}

@media (max-width: 1250px) {
  .products__modal-title {
    font-size: 24px;
    line-height: 28.2px;
  }

  .products__modal-btn {
    padding: 3px 18px 3px 3px;
    font-size: 13.45px;
  }

  .products__modal-btn-icon {
    font-size: 17px;
  }

  .products__modal-img {
    width: 300px;
    margin: auto;
  }
}

@media (max-width: 950px) {
  .products__wrapper {
    gap: 27px 18px;
    margin-top: 50px;
  }

  .products__item {
    width: 46%;
    padding: 20px 10px 30px;
    border-radius: 10px;
  }

  .products__item-img {
    width: 60%;
  }

  .products__modal {
    flex-direction: column-reverse;
    /* top: 5vw; */
    gap: 50px;
  }

  .products__modal-content {
    gap: 30px;
    min-width: 100%;
  }

  .products__modal-img {
    width: 300px;
    margin: auto;
  }
}

@media (max-width: 850px) {
  .products__modal {
    padding: 35px 38px;
    border-radius: 20px;
  }
}

@media (max-width: 700px) {
  .products__modal {
    gap: 29px;
  }

  .products__modal-btn {
    padding: 2px 15px 2px 2px;
    font-size: 13.45px;
  }

  .products__modal-btn-icon {
    padding: 8px;
  }

  .products__modal-title {
    font-size: 19px;
    line-height: 23.2px;
  }

  .products__modal-detail {
    font-size: 15px;
  }
}

@media (max-width: 700px) {
  .products__item-btn {
    font-size: 25px;
  }
}

@media (max-width: 500px) {
  .products__item-img {
    width: 80%;
    height: 100px;
  }

  .products__item-title {
    font-size: 14px;
    line-height: 17.8px;
  }

  .products__modal-title + p {
    font-size: 13px;
  }

  .products__modal {
    width: 80%;
    /* top: 10%; */
  }

  .products__modal-img {
    width: 60%;
  }

  .products__modal-title {
    font-size: 17px;
    line-height: 21.2px;
  }

  .products__modal-content {
    gap: 23px;
  }

  .products__item-btns {
    right: -18px;
  }

  .products__item-btn {
    font-size: 20px;
    padding: 9px;
  }
}
